<template>
    <div ref="main" class="main" :class="{ active: load }">
        <loadingSecRe :class="{ active: load }"/>
        <div class="swiper">
            <div class="main-content swiper-wrapper">
                <div ref="content1" class="content content1 swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                        <img class="snow" src="../../assets/bg/snow.gif" alt="">
                    </div>
                    <div class="right_img">
                        <img src="../../assets/bg/greenery_right.png" alt="">
                        <img class="snow" src="../../assets/bg/snow.gif" alt="">
                    </div>
                    <img class="line-img" src="../../assets/bg/line.svg" alt="">
                    <div class="section shadow">
                        <div class="section-box">
                            <p class="title">部门<br />报告</p>
                            <p class="time"><span>{{ startDate }}</span> <br />|<br /> <span>{{ endDate }}</span></p>
                            <span class="line"></span>
                            <p class="vice-title">融媒体中心发布</p>
                            <p class="years">{{ years }}</p>
                        </div>
                    </div>
                </div>
                <div ref="content2" class="content content2 swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                        <!-- <img class="snow" src="../../assets/bg/snow.gif" alt=""> -->
                    </div>
                    <div class="right_img">
                        <img src="../../assets/bg/greenery_right.png" alt="">
                        <!-- <img class="snow" src="../../assets/bg/snow.gif" alt=""> -->
                    </div>
                    <div class="section shadow">
                        <p class="title">尊敬的{{ name }}：</p>
                        <p class="body-text">您好！点开本周阅读数据报告，又是心动的感觉！</p>
                        <p class="title2" v-if="false">基本数据</p>
                        <div id="sex-echart" v-if="false" style="width: 100%; height: 35vh; padding: 0 calc(16 * 100vh /844);"></div>
                        <div class="quantity" v-if="false">
                            <p class="qu_title">总人数<span>{{ sexOption.series[0].data[0].value+sexOption.series[0].data[1].value }}</span>人</p>
                            <p class="p1">
                                <img src="../../assets/bg/nan.svg" alt="">
                                男<span>{{ sexOption.series[0].data[0].value }}</span>人
                            </p>
                            <p class="p2">
                                <img src="../../assets/bg/nv.svg" alt="">
                                女<span>{{ sexOption.series[0].data[1].value }}</span>人
                            </p>
                        </div>
                    </div>
                </div>
                <div ref="content3" class="content content3 swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                    </div>
                    <div class="right_img">
                        <img src="../../assets/bg/greenery_right.png" alt="">
                        <!-- <img class="snow" src="../../assets/bg/snow.gif" alt=""> -->
                    </div>
                    <div class="section">
                        <!-- <p class="title">基本数据</p>
                        <div id="sex-echart" style="width: 100%; height: 35vh; padding: 0 16px;"></div>
                        <div class="quantity">
                            <p class="qu_title">总人数<span>{{ sexOption.series[0].data[0].value+sexOption.series[0].data[1].value }}</span>人</p>
                            <p class="p1">
                                <img src="../../assets/bg/nan.svg" alt="">
                                男<span>{{ sexOption.series[0].data[0].value }}</span>人
                            </p>
                            <p class="p2">
                                <img src="../../assets/bg/nv.svg" alt="">
                                女<span>{{ sexOption.series[0].data[1].value }}</span>人
                            </p>
                        </div> -->
                        <div class="vice-title">本周期</div>
                        <ul class="shadow ul1">
                            <li>累计阅读量<span>{{ deptReadInfo['count'] }}</span></li>
                            <li>累计阅读量<span>{{ deptReadInfo['count_lj'] }}</span></li>
                            <li>累计阅读量<span>{{ deptReadInfo['count_zd'] }}</span></li>
                        </ul>
                        <ul class="shadow ul2">
                            <li>累计文章阅读人次<span>{{ deptReadInfo['perCount'] }}</span></li>
                            <li>人均阅读人次<span>{{ readPerCount }}</span></li>
                        </ul>
                        <ul class="shadow ul3">
                            <li>累计点赞<span>{{ deptReadInfo['like'] }}</span></li>
                            <li>累计分享<span>{{ deptReadInfo['share'] }}</span></li>
                            <li>累计留言<span>{{ deptReadInfo['comment'] }}</span></li>
                        </ul>
                        <p class="describe pdlr16">
                            <img src="../../assets/bg/success.svg" alt="">
                            成绩是大家的，重在参与
                        </p>
                    </div>
                </div>
                <div ref="content4" class="content content4 swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                    </div>
                    <p class="title">单位发文</p>
                    
                    <div class="section pdlr16">
                        <p class="p1">单位头条累计发文<span>{{ emptyData(deptPubContent["pubZdContent"]) }}</span>篇，原创<span>{{ emptyData(deptPubContent["pubZdYuanchuang"]) }}</span>篇。</p>
                        <i class="line line1"></i>
                        <p class="p2">单位文章阅读量<span>{{ deptReadInfo["count_zd"] }}</span></p>
                        <i class="line line2"></i>
                        <p class="p3">单位文章阅读率<span>{{ readRate }}</span></p>
                        <i class="line line3"></i>
                        <p class="p4">环比<span>{{ contentStr }}</span></p>
                        <i class="line line4"></i>
                        <p class="p5">单位点赞量<span>{{ deptReadInfo["like_zd"] }}</span></p>
                        <i class="line line5"></i>
                        <p class="p6">单位留言量<span>{{ deptReadInfo["comment_zd"] }}</span></p>
                        <i class="line line6"></i>
                    </div>
                    <p class="describe pdlr16">
                        <img src="../../assets/bg/success.svg" alt="">
                        小编幸苦啦
                    </p>
                </div>
                <div ref="content5" class="content content5 swiper-slide">
                    <div class="section">
                        <p class="title">单位活跃</p>
                        <p class="vice-title"><span class="text">单位活跃排行：第</span><span class="num">{{ unitActivity.unitRank }}</span><span class="text">名</span></p>
                        <div class="unit-rank pdlr16">
                            <p class="p1">单位活跃<span>{{ unitActivity.countTotal }}</span></p>
                            <i class="line line1"></i>
                            <p class="p2">人均活跃<span>{{ unitActivity.capita }}</span></p>
                            <i class="line line2"></i>
                            <p class="p3">环比<span>{{ unitActivity.huanbi }}</span></p>
                        </div>
                        <p class="vice-title vice-title2">活跃分布</p>
                        <div class="act-distribute pdlr16">
                            <p class="p1">阅读<span>{{ unitActivity.count }}</span></p>
                            <i class="line line1"></i>
                            <p class="p2">点赞<span>{{ unitActivity.like }}</span></p>
                            <i class="line line2"></i>
                            <p class="p3">评论<span>{{ unitActivity.comment }}</span></p>
                            <i class="line line3"></i>
                            <p class="p4">分享<span>{{ unitActivity.share }}</span></p>
                        </div>
                        <p class="vice-title vice-title3">榜上有名</p>
                        <div class="act-rank pdlr16">
                            <p class="p1">No.1 <span class="span1">{{ unitActivity.rankList[0].name }}</span><span>{{ unitActivity.rankList[0].active }}</span></p>
                            <i class="line line1"></i>
                            <p class="p2">No.2 <span class="span1">{{ unitActivity.rankList[1].name }}</span><span>{{ unitActivity.rankList[1].active }}</span></p>
                            <i class="line line2"></i>
                            <p class="p3">No.3 <span class="span1">{{ unitActivity.rankList[2].name }}</span><span>{{ unitActivity.rankList[2].active }}</span></p>
                        </div>
                    </div>
                    <p class="describe pdlr16">
                        <img src="../../assets/bg/success.svg" alt="">
                        脱颖而出的，好像会发光！
                    </p>
                </div>
                <div ref="content6" class="content content6 swiper-slide">
                    <div class="right_img">
                        <img src="../../assets/bg/greenery_right.png" alt="">
                    </div>
                    <div class="section">
                        <p class="title">单位积分</p>
                        <ul class="pdlr16 list1">
                            <li class="li1"><p>总积分 <span>{{ unitPoints.point }}</span></p> <i></i></li>
                            <li class="li2"><p>人均积分 <span>{{ unitPoints.capita }}</span></p> <i></i></li>
                            <li class="li3"><p>环比增长 <span>{{ unitPoints.increase }}</span></p></li>
                        </ul>
                        <p class="vice-title"><span>积分分布</span></p>
                        <ul class="pdlr16 list2">
                            <li v-for="(item,ind) in unitPoints.option.series[0].data" :key="ind" :class="'item' + (ind + 1)">
                                <span class="block" :style="{backgroundColor: unitPoints.option.color[ind]}"></span>
                                <span>{{ item.name }}</span>
                            </li>
                        </ul>
                        <div id="cont6-echart" style="width: 100%; height: 40%; padding: 0 16px; margin-top: -45px;"></div>
                    </div>
                    <p class="describe pdlr16">
                        <img src="../../assets/bg/success.svg" alt="">
                        你一分我一分，每天来几分。
                    </p>
                </div>
                <div ref="content7" class="content content7 swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                    </div>
                    <div class="section shadow">
                        <p class="title">阅读时长</p>
                        <p class="vice-title vice-title1">总阅读</p>
                        <div class="read-ov-rank pdlr16">
                            <p class="p1">总阅读时长<span class="span1">{{ readRank.over['总阅读时长'] }}</span></p>
                            <i class="line line1"></i>
                            <p class="p2">人均阅读时长<span class="span2">{{ readRank.over['人均阅读时长'] }}</span></p>
                            <i class="line line2"></i>
                            <p class="p3">进入次数<span class="span3">{{ readRank.over['进入次数'] }}</span></p>
                        </div>
                        <p class="vice-title vice-title2">单位阅读</p>
                        <div class="platform pdlr16">
                            <p class="p1">头条平台阅读时长<span class="span1">{{ readRank.platform['头条平台阅读时长'] }}</span></p>
                            <i class="line line1"></i>
                            <p class="p2">单位平台阅读时长<span class="span2">{{ readRank.platform['单位平台阅读时长'] }}</span></p>
                            <i class="line line2"></i>
                            <p class="p3">矩阵阅读时长<span class="span3">{{ readRank.platform['矩阵阅读时长'] }}</span></p>
                        </div>
                        <img class="read-img" src="../../assets/bg/read.svg" alt="">
                        <p class="describe pdlr16">
                            <img src="../../assets/bg/success.svg" alt="">
                            养成良好的阅读习惯，增加知识积累，广铁平台为你保驾护航
                        </p>
                    </div>
                </div>
                <div ref="footer" class="footer swiper-slide">
                    <div class="left_img">
                        <img src="../../assets/bg/greenery_left.png" alt="">
                        <img class="snow" src="../../assets/bg/snow.gif" alt="">
                    </div>
                    <div class="right_img">
                        <img src="../../assets/bg/greenery_right.png" alt="">
                        <img class="snow" src="../../assets/bg/snow.gif" alt="">
                    </div>
                    <div class="section shadow">
                        <p class="pdlr16">请督促本部门职工加强阅读，保持良好阅读习惯哦，加油！</p>
                        <!-- <img src="../../assets/bg/litpic-modified.png" alt=""> -->
                        <img src="../../assets/bg/end.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { statsPost, getRadioDate, formatSecond, getYearOnYearBasisDate } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import Swiper from 'swiper';
import * as echarts from 'echarts';
import { find } from '../../components/utils';
import loadingSecRe from '../../components/loadingSecRe.vue'
    export default {
        data() {
            return {
                deptReadInfo: {
                    name: "",
                    Positive: 0,
                    Neutral: 0,
                    Negative: 0,
                    count: 0,
                    like: 0,
                    share: 0,
                    comment: 0,
                    commentView: 0,
                    count_lj: 0,
                    count_zd: 0,
                    like_lj: 0,
                    like_zd: 0,
                    share_lj: 0,
                    share_zd: 0,
                    comment_lj: 0,
                    comment_zd: 0,
                    perCount: 0,
                    totalNum: 0,
                    ljNum: 0,
                    ljTime_s: 0,
                    zdNum: 0,
                    zdTime_s: 0,
                    jzNum: 0,
                    jzTime_s: 0,
                    signIntegral: 0,
                    clickIntegral: 0,
                    readingTimeIntegral: 0,
                    readingVideoIntegral: 0,
                    readingVideoTimeCountIntegral: 0,
                    likeIntegral: 0,
                    shareIntegral: 0,
                    commentIntegral: 0,
                    integralAll: 0,
                    wxRun: 0,
                },
                deptReadOldInfo: {
                    Positive: 0,
                    Neutral: 0,
                    Negative: 0,
                    count: 0,
                    like: 0,
                    share: 0,
                    comment: 0,
                    commentView: 0,
                    count_lj: 0,
                    count_zd: 0,
                    like_lj: 0,
                    like_zd: 0,
                    share_lj: 0,
                    share_zd: 0,
                    comment_lj: 0,
                    comment_zd: 0,
                    perCount: 0,
                    totalNum: 0,
                    ljNum: 0,
                    ljTime_s: 0,
                    zdNum: 0,
                    zdTime_s: 0,
                    jzNum: 0,
                    jzTime_s: 0,
                    signIntegral: 0,
                    clickIntegral: 0,
                    readingTimeIntegral: 0,
                    readingVideoIntegral: 0,
                    readingVideoTimeCountIntegral: 0,
                    likeIntegral: 0,
                    shareIntegral: 0,
                    commentIntegral: 0,
                    integralAll: 0,
                    wxRun: 0,
                },
                startDate: '2023-11-19',
                endDate: '2023-11-25',
                years: '2023',
                name: 'XXX',
                deptIdArr: [],
                sexOption: { // 基本数据男女生图表
                    tooltip: {
                        trigger: 'item'
                    },
                    // color: ['#5ebfb8','#5470c6'],
                    legend: {
                        top: '5%',
                        left: 'left'
                    },
                    series: [
                        {
                            left: '-30%',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2,
                                // shadowBlur: 200,
                                // shadowColor: 'rgba(0, 0, 0, .1)'
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                { value: 1048, name: '男' },
                                { value: 735, name: '女' }
                                // { value: 735, name: '女',itemStyle: { color: 'pink' } }
                            ]
                        }
                    ]
                },
                readData:{ // 单位发文数据
                    '累计阅读量': 235,
                    '集团阅读量': 223,
                    '单位阅读量': 2,
                    '文章阅读人数': 20,
                    '人均阅读人次': 121,
                    '累计点赞': 240,
                    '累计分享': 30,
                    '累计留言': 390,
                },
                deptPubContent: {},
                readPerCount: 0,
                readRate: 0,
                contentStr: 0,
                unitActivity: { // 单位活跃数据
                    unitRank: 0,
                    countTotal: 0,
                    capita: 0,
                    huanbi: 0,
                    count: 0,
                    like: 0,
                    comment: 0,
                    share: 0,
                    rankList: [
                        {
                            name: '傅金海',
                            active: 345
                        },
                        {
                            name: '曾佳梅',
                            active: 317
                        },
                        {
                            name: '吕梦瑶',
                            active: 292
                        }
                    ]
                },
                unitPoints: { //单位积分
                    point: 5864,
                    capita: 75.18,
                    increase: '',
                    option: {
                        tooltip: {
                            trigger: 'item'
                        },
                        // legend: {
                        //     width: '50%',
                        //     top: '5%',
                        //     left: 'left'
                        // },
                        color:['#5470c6', '#91cc75', '#d3ecdc', '#6db2a2', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                        series: [
                            {
                                name: '单位活跃分布',
                                type: 'pie',
                                radius: ['40%', '70%'],
                                avoidLabelOverlap: false,
                                // top: '25%',
                                // bottom: 0,
                                itemStyle: {
                                    borderRadius: 6,
                                    borderColor: '#fff',
                                    borderWidth: 2
                                },
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                    show: true,
                                    fontSize: 30,
                                    fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { value: 0, name: '阅读' },
                                    { value: 0, name: '点赞' },
                                    { value: 0, name: '评论' },
                                    { value: 0, name: '分享' },
                                    { value: 0, name: '签到' },
                                    { value: 0, name: '阅读时长' }
                                ]
                            }
                        ]
                    }
                },
                readRank: { // 阅读数据
                    over: {
                        '总阅读时长': 57.69,
                        '人均阅读时长': 0.74,
                        '进入次数': 3567
                    },
                    platform: {
                        '头条平台阅读时长': 55.32,
                        '单位平台阅读时长': 0.19,
                        '矩阵阅读时长': 2.18
                    }
                },
                load: true // 测试时为false
            }
        },
        components: {
            loadingSecRe
        },
        async created () {
            // 取周期
            const startDate = this.getQueryString("s");
            const endDate = this.getQueryString("e");

            this.startDate = startDate
            ? moment(parseInt(startDate)).format("YYYY-MM-DD")
            : "";
            this.endDate = endDate
            ? moment(new Date(parseInt(endDate))).format("YYYY-MM-DD")
            : "";
            // console.log(this.startDate);
            // console.log(this.endDate);
            // if (!this.startDate || !this.endDate) {
            //     this.showNoDate = false;
            // }

            const deptId = this.getQueryString("deptId");
            wxLoginApi.onReady(async () => {
                try {
                    this.wxUser = wxLoginApi.getUser();
                    // console.log('213131', this.wxUser);
                    // this.name = this.wxUser.name // 部门名
                    // 如果置顶ID 查指定的id
                    if (!deptId) {
                        // 判断用户权限，当用户无权限，查询用户自己所在部门的3级
                        const deptPubContent = await statsPost(
                            "/dept/getUserDeptAndContent",
                            {
                                startDate: moment(this.startDate).format("YYYY/MM/DD"),
                                endDate: moment(this.endDate).format("YYYY/MM/DD"),
                            }
                        );
                        
                        
                        // 查询用户所在部门
                        const user3Dept = _.get(deptPubContent, ["parentDept", 2], "");
                        this.deptIdArr = user3Dept
                            ? [user3Dept]
                            : [deptPubContent["parentDept"][2]];
                        this.deptPubContent = deptPubContent;
                        // console.log('qqq----',this.deptIdArr);
                        this.name = this.deptPubContent.name // 部门名
                        await this.getRadioAndYearOnYearData()
                        await this.onRefresh()
                        await this.getJIfen()
                        await this.getReadData()
                    } else {
                        this.deptIdArr = [parseInt(deptId)];
                    }
                    // console.log("loginInok-------------");
                    await this.userData()
                    // this.initData();
                    // this.showLoad = true;
                } catch (error) {
                    console.log("-----init 0", error);
                    // this.showLoad = false;
                }
            });
        },
        mounted() {
            var _that = this
            new Swiper('.swiper', {
                direction: 'vertical', // 垂直切换选项
                on: {
                    slideChangeTransitionStart: function () {
                        // if(this.activeIndex == 1) {
                        //     _that.content2()
                        // }
                        // else
                         if(this.activeIndex == 3) {
                            // _that.content4()
                        }else if(this.activeIndex == 4) {
                            // _that.content5()
                        }else if(this.activeIndex == 5) {
                            _that.content6()
                        }else if(this.activeIndex == 6) {
                            // _that.content7()
                        }
                    }
                }
            })
            this.content1()
            let aaa = {}
            aaa['人均阅读时长'] = 'asdnj'
            
            console.log(Object.keys(aaa));
            // this.content2()

            // this.content3()

            // this.content4()

            // this.content5()

            // this.content6()

            // this.content7()

            this.footer()
        },
        methods: {
            content1() {
                const $content = this.$refs.content1
                const $l_img = find($content, '.left_img')
                const $r_img = find($content, '.right_img')
                const $section = find($content, '.section')
                const $title = find($section, '.title')
                const $time = find($section, '.time')
                const $line = find($section, '.line')
                const $vice = find($section, '.vice-title')
                const $years = find($section, '.years')
                let tl = this.gsap.timeline()
                setTimeout(()=>{
                    tl
                        .to($l_img, 1, { scale: 1 })
                        .to($r_img, 1, { scale: 1 }, '-=1')
                        .to($section, 1, { scale: 1 }, '-=1')
                        .to($title, 1, { scale: 1 }, '-=.5')
                        .to($time, 1, { scale: 1 }, '-=.5')
                        .to($line, 1, { width: '50%' }, '-=.5')
                        .to($vice, 1, { scale: 1 }, '-=.5')
                        .to($years, 1, { scale: 1 }, '-=.5')
                    
                },1950)
                setTimeout(()=> {
                    this.load = false
                },3000)
            },
            content2() {
                const $content = this.$refs.content2
                // const $echart = echarts.init(find($content, '#sex-echart'))

                // setTimeout(() => {
                //     $echart.setOption(this.sexOption);
                // }, 1500);
                // const $title = find($section, '.title')
                // const $text = find($section, '.body-text')
                // const tl = this.gsap.timeline()
                // this.ScrollTrigger.create( {
                //     trigger: $section,
                //     start: "top 85%",
                //     end: "bottom -50%",
                //     // markers: true,
                //     toggleClass: 'active', // 在触发区域时自动切换类名
                // })
            },
            content3() {
                const $content = this.$refs.content3
                
                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 90%",
                    end: "bottom -50%",
                    toggleClass: 'active',
                })
                const $echart = echarts.init(find($content, '#sex-echart'))

                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 80%",
                    onEnter:()=>{
                        $echart.setOption(this.sexOption);
                    }
                })
            },
            content4() {
                const $content = this.$refs.content4

                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 90%",
                    end: "bottom -50%",
                    toggleClass: 'active',
                })
            },
            content5() {
                const $content = this.$refs.content5

                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 90%",
                    end: "bottom -50%",
                    toggleClass: 'active',
                })
            },
            content6() {
                const $content = this.$refs.content6
                const echart = echarts.init(document.getElementById('cont6-echart'));
                setTimeout(() => {
                    echart.setOption(this.unitPoints.option);
                }, 3500);
            },
            content7() {
                const $content = this.$refs.content7
                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 90%",
                    end: "bottom -50%",
                    toggleClass: 'active',
                })
            },
            footer() {
                const $content = this.$refs.footer
                this.ScrollTrigger.create({
                    trigger: $content,
                    start:"top 90%",
                    end: "bottom -50%",
                    toggleClass: 'active',
                })
            },
            getRadio (newVal, oldVal, fixNum) {
                let _fixNum = fixNum || 2;
                if (fixNum == 0) {
                    _fixNum = 0;
                }
                let _differenceValue = 0;
                if (oldVal) {
                    _differenceValue = ((newVal - oldVal) / oldVal) * 100;
                }
                return _differenceValue;
            },
            emptyData (data) {
                if (_.isEmpty(data)) {
                    return '0';
                } else {
                    return data;
                }
            },
            async initData () {
                let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                // 查单位数据
                await this.getDeptData(this.startDate, this.endDate, "curData");
                await this.getDeptData(startDate, endDate, "radioData");
                // 查发文
                await this.getDeptContentNum(this.startDate, this.endDate, "curData"); // new
                await this.getDeptContentNum(startDate, endDate, "radioData"); // old

                // this.showUserRead = true;

                // 处理显示字符串
                // 计算文章环比

                if (
                    this.deptPubContent.pubZdContent > 0 &&
                    this.deptReadInfo.yjh > 0 &&
                    this.deptReadInfo.count_zd
                ) {
                    this.readRate = (
                    this.deptReadInfo.count_zd /
                    (this.deptPubContent.pubZdContent * this.deptReadInfo.yjh)
                    ).toFixed(2);
                }

                if (
                    this.deptPubContent.pubOldZdContent > 0 &&
                    this.deptReadInfo.yjh > 0
                ) {
                    this.readOldRate = (
                    this.deptReadOldInfo.count_zd /
                    (this.deptPubContent.pubOldZdContent * this.deptReadInfo.yjh)
                    ).toFixed(2);
                }

                const contentHuanBi = this.getRadio(this.readRate, this.readOldRate);
                // console.log('aaaaaa',contentHuanBi);
                // console.log('contentHuanBi--------', contentHuanBi)
                if (contentHuanBi) {
                    
                    this.contentStr = contentHuanBi > 0 ? "+" : "-";
                    this.contentStr += contentHuanBi
                    ? `${Math.abs(contentHuanBi).toFixed(2)}%`
                    : `${contentHuanBi.toFixed(2)}%`;

                }

                if (this.deptReadInfo["yjh"] && this.deptReadInfo["perCount"]) {
                    this.readPerCount = (
                    this.deptReadInfo["perCount"] / this.deptReadInfo["yjh"]
                    ).toFixed(2);
                }

                this.showText =
                    contentHuanBi > 0
                    ? "“请继续努力，督促本部门职工保持良好阅读习惯哦！"
                    : "请督促本部门职工加强阅读，保持良好阅读习惯哦，加油！";
            },
            async getDeptData (startDate, endDate, type) {
                try {
                    if (this.dataTimer) {
                        clearTimeout(this.dataTimer);
                    }

                    // this.showLoad = true;

                    const ret = await statsPost("/report/deptReport", {
                        startDate,
                        endDate,
                        deptId: this.deptIdArr[0],
                    });

                    // runSeconds: 0
                    // status: "等待中"
                    // taskIndex: 1
                    // taskName: "report_2022-11-04_2022-11-30_"
                    if (ret.status) {
                    if (this.dataTimer) {
                        clearTimeout(this.dataTimer);
                    }

                    this.dataTimer = setTimeout(async () => {
                        // this.showLoad = false;
                        await this.getDeptData(startDate, endDate, type);
                    }, 2000);
                    return;
                    }
                    if (ret.ret) {
                        if (type == "radioData") {
                            this.deptReadOldInfo = ret.ret;
                        } else {
                            this.deptReadInfo = ret.ret;
                            const ljTime_s = ret.ret.ljTime_s || 0;
                            const zdTime_s = ret.ret.zdTime_s || 0;
                            const jzTime_s = ret.ret.jzTime_s || 0;
                            const allTime_s = ljTime_s + zdTime_s + jzTime_s;
                            this.deptReadInfo["allTime_s"] = allTime_s
                            ? formatSecond(allTime_s, "hour")
                            : "";
                        }
                    }
                    // console.log(this.deptReadInfo);

                    // this.showLoad = false;
                } catch (error) {
                    // this.showLoad = false;
                    if (this.dataTimer) {
                        clearTimeout(this.dataTimer);
                    }
                }
            },
            // 截取字符串
            getQueryString (name) {
                const url = window.location.href;
                const r = url.match(
                    // eslint-disable-next-line
                    new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
                );
                if (r !== null) {
                    const str = decodeURI(r[2]);
                    const pos = str.indexOf("#/");
                    if (pos >= 0) {
                        return str.substr(0, pos);
                    }
                    return str;
                }
                return null;
            },
            async getDeptContentNum (startDate, endDate, type) {
                try {
                    // this.showLoad = true;
                    // TODO: 建议集团报告时，发文原创查询部门id为'lj'，单位阅读量、阅读lv使用路局文章的数
                    // if(1==this.deptIdArr[0]) {
                    //   deptId = 'lj'
                    // }
                    const ret = await statsPost("/report/getDeptContentNum", {
                        startDate,
                        endDate,
                        deptId: this.deptIdArr[0],
                    });

                    if (ret.ret) {
                    if (type == "radioData") {
                        // 环比
                        this.deptPubContent["pubOldZdContent"] = ret.ret.conentNumLen; // old推文总数
                        this.deptPubContent["pubOldZdYuanchuang"] = ret.re.originalNum; // //原创数
                    } else {
                        this.deptPubContent["pubZdContent"] = ret.ret.conentNumLen; // new
                        this.deptPubContent["pubZdYuanchuang"] = ret.ret.originalNum;
                    }
                    }
                    // this.showLoad = false;
                } catch (error) {
                    // this.showLoad = false;
                    if (this.dataTimer) {
                        clearTimeout(this.dataTimer);
                    }
                }
                },
            async userData () {
                // console.log("userData ok ----------", this.showLoad, this.deptIdArr);
                // if (!this.showLoad) {
                // 判断用户权限，当用户无权限，查询用户自己所在部门的4级
                if (this.deptIdArr.length == 0) {
                    // 查询用户所在部门
                    const userinfo = await statsPost("/dept/getUserDept");
                    const user3Dept = _.get(userinfo, ["parentDept", 2], "");
                    this.deptIdArr = user3Dept ? [user3Dept] : [userinfo["parentDept"][2]];
                }
                // console.log('4rgbn',this.deptIdArr);
                this.initData();
                // this.showLoad = false;
                // }
            },
            async commonRatioDataFun(query, huanbiType, type, typeVal) {
                this.clearTimmerFun();
                try {
                    let _query = query;
                    //  计算周期
                    let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                    // if (huanbiType == "tongbi") {
                    //     const dateObj = getYearOnYearBasisDate(this.startDate, this.endDate);
                    //     startDate = dateObj.startDate;
                    //     endDate = dateObj.endDate;
                    // }
                    let queryOld = {
                        startDate: startDate,
                        endDate: endDate,
                        tjLevel: 2,
                        // deptIdArr: this.deptIdArr
                    }
                    _query["startDate"] = this.startDate;
                    _query["endDate"] = this.endDate;
                    const ret = await statsPost('/deptTj/tjReadInfo', _query);
                    const retOld = await statsPost('/deptTj/tjReadInfo', queryOld);

                
                    if (ret.status && retOld.status) {
                        this.radioTimer = setTimeout(async () => {
                            await this.commonRatioDataFun(query, huanbiType, type, typeVal);
                        }, 4000);
                    } else {
                        const data = _.get(ret, ["ret"], []);
                        const dataOld = _.get(retOld, ["ret"], []);
                        let arr = data.map(item => {
                            return item.comment + item.count + item.like + item.share
                        })
                        let arr2 = data.map(item => {
                            if(item.comment + item.count + item.like + item.share) {
                                return ((item.comment + item.count + item.like + item.share)/item.userStatusCount).toFixed(2)
                            }else {
                                return 0
                            }
                        })
                        let ind
                        data.forEach((item,index) => {
                            if(item.deptId == this.deptIdArr[0]) { ind = index }
                            return ind
                        })
                        this.unitActivity.unitRank = arr2.slice().sort((a, b) => b - a).indexOf(arr2[ind]) + 1
                        this.unitActivity.countTotal = arr[ind] || 0
                        this.unitActivity.capita = arr2[ind] || 0
                        this.unitActivity.count = data[ind].count
                        this.unitActivity.like = data[ind].like
                        this.unitActivity.comment = data[ind].comment
                        this.unitActivity.share = data[ind].share
                        // console.log('data',dataOld);
                        let oldCount = dataOld[ind].comment + dataOld[ind].count + dataOld[ind].like + dataOld[ind].share
                        const contentHuanBi = this.getRadio(this.unitActivity.countTotal, oldCount);
                        // console.log('aaaaaa',oldCount);
                        // console.log('contentHuanBi--------', contentHuanBi)
                        if (contentHuanBi) {
                            
                            this.unitActivity.huanbi = contentHuanBi > 0 ? "增长" : "降低";
                            this.unitActivity.huanbi += contentHuanBi
                            ? `${Math.abs(contentHuanBi).toFixed(2)}%`
                            : `${contentHuanBi.toFixed(2)}%`;

                        }

                    }
                    // if (retOld.status) {

                    // }
                } catch (error) {
                    this.clearTimmerFun();
                }
            },
            dealCommonRadioData(data, type, typeVal) {
                let activeAll = 0;
                for (const iterator of this.dataList) {
                    // 计算环比差
                    const _huanBiData = _.find(data, (o) => {
                    return o.deptId == iterator.deptId;
                    });

                    if (_huanBiData) {
                    let allNum =
                        _huanBiData["comment"] +
                        _huanBiData["count"] +
                        _huanBiData["like"] +
                        _huanBiData["share"];

                    let averageActive = 0;
                    if (iterator.userStatusCount > 0) {
                        averageActive = allNum / iterator.userStatusCount;
                    }
                    activeAll += allNum;

                    // 环比增长率=（本期数-上期数）/上期数×100%
                    iterator[type] = getRadio(iterator["averageActive"], averageActive);

                    iterator[type.slice(0, 2)] = parseFloat(iterator[type]);
                    iterator[typeVal] = averageActive;
                    }
                }

                let averageActive = 0;
                if (this.huizongData["userStatusCount"]) {
                    averageActive = activeAll / this.huizongData["userStatusCount"];
                }

                this.huizongData["环比增长"] = getRadio(
                    this.huizongData["averageActive"],
                    averageActive
                );

                // this.$nextTick(() => {
                //     this.$forceUpdate();
                // });
            },
            async getRadioAndYearOnYearData() {
                try {
                    // this.$Message.info("环比数据查询中");
                    let query = {
                        startDate: '',
                        endDate: '',
                        tjLevel: 2
                    }
                    await this.commonRatioDataFun(query, "huanbi", "环比增长", "上期数");
                } catch (error) {
                    console.log("------", error);
                }
            },
            async onRefresh() {
                try {
                    // this.showLoad = true;
                    let _query = {
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };

                    if (this.deptIdArr.length > 0) {
                        _query["deptIdArr"] = this.deptIdArr;
                        _query["tjLevel"] = 2;
                    }
                    const ret = await statsPost('/deptTj/userActivity', _query);

                    let deptData = _.get(ret, ["ret"], []).slice(0, 3);
                    // let arr = []
                    this.unitActivity.rankList = []
                    deptData.forEach(item => {
                        var obj = {
                            name: '',
                            active: 0
                        }
                        obj.name = item.name
                        obj.active = item.comment + item.count + item.like + item.share
                        this.unitActivity.rankList.push(obj)
                    })
                    // console.log('deptData',this.unitActivity.rankList);
                } catch (error) {
                    console.log("----", error);
                }
            },
            // 阅读时长
            async readTime(query, huanbiType, type, typeVal) {
                this.clearTimmerFun();
                try {
                    let _query = query;
                    const ret = await statsPost('/deptTj/tjReadInfo', _query);
                    // console.log('zzzzzlzlzl',ret);
                    if (ret.status) {
                        this.dataTimer = setTimeout(async () => {
                            await this.readTime(query, huanbiType, type, typeVal);
                        }, 4000);
                    } else {
                        const data = _.get(ret, ["ret"], []);
                        // console.log('this.deptIdArr[0]',this.deptIdArr[0]);
                        let ind = data.filter((item,index) => {
                            // if(item.deptId == this.deptIdArr[0]) { ind = item }
                            return item.deptId == this.deptIdArr[0]
                        })
                        // console.log('data',ind[0]);
                        let time = ((ind[0].ljTime_s + ind[0].jzTime_s + ind[0].zdTime_s) / 60 / 60).toFixed(2)
                        this.readRank.over['总阅读时长'] = ((ind[0].ljTime_s + ind[0].jzTime_s + ind[0].zdTime_s) / 60 / 60).toFixed(2)
                        this.readRank.over['人均阅读时长'] = (time/ind[0].userStatusCount).toFixed(2)
                        this.readRank.over['进入次数'] = ind[0].totalNum
                        this.readRank.platform['头条平台阅读时长'] = (ind[0].ljTime_s / 60 / 60).toFixed(2) // 头条
                        this.readRank.platform['单位平台阅读时长'] = (ind[0].zdTime_s / 60 / 60).toFixed(2) // 单位
                        this.readRank.platform['矩阵阅读时长'] = (ind[0].jzTime_s / 60 / 60).toFixed(2) // 矩阵
                        // log
                        // console.log('time',(time/ind[0].userStatusCount).toFixed(2));
                        // this.dealCommonRadioData(data, type, typeVal);
                    }
                } catch (error) {
                    this.clearTimmerFun();
                }
            },
            async getReadData(query) {
                try {
                    // this.$Message.info("环比数据查询中");
                    let query = {
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                    await this.readTime(query, "huanbi", "环比增长", "上期数");
                } catch (error) {
                    console.log("------", error);
                }
            },

            async getJIfen() {
                this.clearTimmerFun();
                try {
                    let _query = {
                        startDate: this.startDate,
                        endDate: this.endDate
                    };
                    //  计算周期
                    let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                    let queryOld = {
                        startDate: startDate,
                        endDate: endDate
                    }

                    // _query["startDate"] = this.startDate;
                    // _query["endDate"] = this.endDate;

                    const ret = await statsPost('/deptTj/deptJifenTj', _query);
                    const retOld = await statsPost('/deptTj/deptJifenTj', queryOld);

                    if (ret.status && retOld.status) {
                        this.jifenTimer = setTimeout(async () => {
                            await this.getJIfen();
                        }, 4000);
                    } else {
                        const data = _.get(ret, ["ret"], []).filter(item => {
                            return item.deptId == this.deptIdArr[0]
                        })[0];
                        const dataOld = _.get(retOld, ["ret"], []).filter(item => {
                            return item.deptId == this.deptIdArr[0]
                        })[0];
                        this.unitPoints.point = data['平台总积分']
                        this.unitPoints.capita = (data['平台总积分']/data['总人数']).toFixed(2)
                        this.unitPoints.option.series[0].data[0].value = data['视频阅读总积分']
                        this.unitPoints.option.series[0].data[1].value = data['点赞总积分']
                        this.unitPoints.option.series[0].data[2].value = data['评论总积分']
                        this.unitPoints.option.series[0].data[3].value = data['分享总积分']
                        this.unitPoints.option.series[0].data[4].value = data['签到总积分']
                        this.unitPoints.option.series[0].data[5].value = data['阅读时长总积分']

                        const contentHuanBi = this.getRadio(data['平台总积分'], dataOld['平台总积分']);
                        if (contentHuanBi) {
                            
                            this.unitPoints.increase = contentHuanBi > 0 ? "+" : "-";
                            this.unitPoints.increase += contentHuanBi
                            ? `${Math.abs(contentHuanBi).toFixed(2)}%`
                            : `${contentHuanBi.toFixed(2)}%`;

                        }
                    }
                } catch (error) {
                    this.clearTimmerFun();
                }
            },
            clearTimmerFun() {
                if (this.dataTimer) {
                    clearTimeout(this.dataTimer);
                }
                if (this.radioTimer) {
                    clearTimeout(this.radioTimer);
                }
                if (this.jifenTimer) {
                    clearTimeout(this.jifenTimer);
                }
            },
        }

    }
</script>

<style scoped>

    @keyframes flipInX {
        0% {
            transform: perspective(20rem) translate(-50%, -50%) rotateX(90deg);
            animation-timing-function: ease-in;
            opacity: 0;
        }
        40% {
            transform: perspective(20rem) translate(-50%, -50%) rotateX(-20deg);
            animation-timing-function: ease-in;
        }
        60% {
            transform: perspective(20rem) translate(-50%, -50%) rotateX(10deg);
            opacity: 1;
        }
        80% {
            transform: perspective(20rem) translate(-50%, -50%) rotateX(-5deg);
        }
        100% {
            transform: perspective(20rem) translate(-50%, -50%);
        }
    }
    @keyframes swing { /*   animation: 5s ease 0s infinite normal both running swing; */
        20% {
            transform: rotate(15deg);
        }
        40% {
            transform: rotate(-10deg);
        }
        60% {
            transform: rotate(5deg);
        }
        80% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .shen {
        color: #6db2a3;
    }
    .qian {
        color: #d3ecdc;
    }
    .border {
       border: 1px solid #6db2a2; 
    }
    .main {
        width: 100%;
        position: relative;
        color: #6db2a2;
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        height: auto;
        overflow: initial;
    }
    .swiper {
        width: 100%;
        height: 100vh;
    }
    .main-content {
        width: 100%;
        height: auto;
        position: relative;
    }
    .main.active {
        height: 100vh;
        overflow: hidden;
    }
    .left_img,.right_img {
        position: absolute;
        pointer-events: none;
    }
    ul,li,ol {
        list-style: none;
    }   
    .left_img img,.right_img img {
        display: block;
        /* .div {

        } */
    }
    .left_img img {
        width: 60%;
    }
    .right_img img {
        width: 55%;
    }
    .pdlr16 {
        /* padding-left: 16px;
        padding-right: 16px; */
        padding-left: calc(16 * 100vh / 844);
        padding-right: calc(16 * 100vh / 844);
    }
    .shadow {
        box-shadow: rgb(255, 255, 255) 0px -2px 8px;
    }
    .left_img .snow,.right_img .snow {
        display: block;
        width: auto;
        /* height: 100%; */
        position: absolute;
        left: 0;
        top: 0;
    }
    /* .left_img {

    } */
    .content {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
    }
    .content1 .left_img,.content1 .right_img,.content1 .section .title,.content1 .section .time,.content1 .section .vice-title,.content1 .section .years {
        transform: scale(0);
    }
    .content1 .section {
        background-color: rgba(214, 240, 223, .82);
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        /* padding: 50px 5px; */
        padding: calc(50 * 100vh / 844) calc(5 * 100vh / 844);
        /* border-radius: 120px; */
        border-radius: calc(120 * 100vh / 844);
        width: 50%;
    }
    .content1 .line-img {
        width: 102%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        animation: 2s ease 3.5s 1 normal both running flipInX;
    }
    /* .content1 .section-box {
    } */
    .content1 .section .title {
        /* font-size: 50px; */
        font-size: calc(50 * 100vh / 844);
        line-height: 1.4;
        font-weight: bold;
        /* margin-bottom: 10px; */
        margin-bottom: calc(50 * 100vh / 844);
    }
    .content1 .section .time {
        line-height: 1.4;
        font-weight: bold;
    }
    .content1 .section .line {
        display: block;
        width: 0;
        /* width: 50%; */
        height: 2px;
        background-color: #6db2a2;
        /* margin: 15px auto; */
        margin: calc(15 * 100vh / 844) auto;
    }
    .content1 .section .years {
        /* font-size: 24px; */
        font-size: calc(24 * 100vh / 844);
        font-weight: bold;
        /* margin-top: 15px; */
        margin-top: calc(15 * 100vh / 844);
    }
    .content1 .right_img {
        bottom: 0;
        right: 0;
        left: auto;
        width: 55%;
    }
    .content1 .right_img img {
        width: 100%;
    }
    .content1 .right_img .snow {
        width: auto;
    }
    .content2 {
        height: auto;
        /* padding-top: 50px; */
        padding-top: calc(50 * 100vh / 844);
    }
    .content2 .left_img {
        top: 25%;
        transform: translate(-15%, -50%) rotateZ(10deg); 
    }
    .content2 .left_img img{
        transform: scale(0); 
        transition: all .3s ease;
    }
    .content2.swiper-slide-active .left_img img{
        transform: none; 
        transition: all 1s ease;
    }
    .content2 .right_img {
        width: 55%;
        right: 10px;
        bottom: 0;
        animation: 5s ease 0s infinite normal both running swingC2;
        /* top: 25%;
        transform: translate(-15%, -50%) rotateZ(10deg);  */
    }
    .content2 .right_img img {
        width: 100%;
        transform: scale(0);
        transition: all .3s;
        /* top: 25%;
        transform: translate(-15%, -50%) rotateZ(10deg);  */
    }
    .content2.swiper-slide-active .right_img img{
        transform: none;
        transition: all 1s ease 1.5s;
    }
    .content2 .section {
        position: relative;
        width: auto;
        /* padding: 50px 16px; */
        padding: calc(50 * 100vh / 844) calc(16 * 100vh / 844);
        /* margin: 0 16px; */
        margin: 0 calc(16 * 100vh / 844);
        background-color: #d3ecdc;
        /* border-radius: 30px; */
        /* left: 50%; */
        top: 50%;
        border-radius: calc(30 * 100vh / 844);
        transform: translateY(-50%) scale(0);
        transition: all .3s ease;
    }
    .content2.swiper-slide-active .section {
        transform: translateY(-50%);
        transition: all 1s ease;
    }
    .content2 .section .title {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        font-weight: bold;
        /* margin-bottom: 50px; */
        margin-bottom: calc(50 * 100vh / 844);
        text-align: left;
        transform: translateY(100px);
        opacity: 0;
        transition: all .3s ease;
    }
    .content2 .section .body-text {
        text-align: justify;
        text-indent: 2em;
        transform: translateY(100px);
        opacity: 0;
        transition: all .3s ease;
    }
    .content2.swiper-slide-active .section .title {
        transform: none;
        opacity: 1;
        transition: all 1s ease 1s;
    }
    .content2.swiper-slide-active .section .body-text {
        text-align: justify;
        text-indent: 2em;
        opacity: 1;
        transform: none;
        transition: all 1s ease 1.3s;
    }
    .content3 {
        height: auto;
    }
    .content3,.content3 .section{
        position: relative;
    }
    .content3 .section {
        /* margin-top: 50px; */
        margin-top: calc(50 * 100vh / 844);
    }
    .content2 .title2,.content4 .title {
        display: inline-block;
        /* padding: 8px 40px; */
        padding: calc(8 * 100vh / 844) calc(40 * 100vh / 844);
        /* border-radius: 20px; */
        border-radius: calc(20 * 100vh / 844);
        text-align: center;
        font-weight: bold;
        color: #ffffff;
        /* font-size: 28px; */
        font-size: calc(28 * 100vh / 844);
        background-color: #6db2a2;
        line-height: 1;
        /* margin-bottom: 30px; */
        margin-bottom: calc(30 * 100vh / 844);
        transform: scale(0);
        transition: all .3s ease;
    }
    .content2 .title2 {
        /* margin: 30px 0; */
        margin: calc(30 * 100vh / 844) 0;
    }
    .content2 #sex-echart {
        transform: scale(0);
    }
    .content2.swiper-slide-active #sex-echart {
        transform: none;
        transition: all 1s ease 1.5s;
    }
    .content2.swiper-slide-active .title2,.content4.swiper-slide-active .title {
        transform: none;
        transition: all 1s ease;
    }
    .content2.swiper-slide-active .title2 {
        transition: all 1s ease 1.5s;
    }
    .content3 .left_img {
        top: 40%;
        transform: translate(-20%, -50%) scale(0);
        transition: all .3s;
    }
    .content3.swiper-slide-active .left_img {
        transform: translate(-20%, -50%) scale(1);
        transition: all 1s ease 1.3s;
    }
    @keyframes swingC3 { /*   animation: 5s ease 0s infinite normal both running swing; */
        20% {
            transform: rotate(15deg) translate(30%, -20%);
        }
        40% {
            transform: rotate(-10deg) translate(30%, -20%);
        }
        60% {
            transform: rotate(5deg) translate(30%, -20%);
        }
        80% {
            transform: rotate(-5deg) translate(30%, -20%);
        }
        100% {
            transform: rotate(0deg) translate(30%, -20%);
        }
    }
    @keyframes swingC2 { /*   animation: 5s ease 0s infinite normal both running swing; */
        20% {
            transform: rotate(15deg);
        }
        40% {
            transform: rotate(-10deg);
        }
        60% {
            transform: rotate(5deg);
        }
        80% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .content3 .right_img {
        width: 55%;
        right: 0;
        bottom: 10%;
        transform: translate(30%, -20%);
        animation: 5s ease 0s infinite normal both running swingC3;
        /* top: 40%;
        transform: translate(-20%, -50%); */
    }
    .content3 .right_img img {
        width: 100%;
        transform: scale(0);
        transition: all .3s;
    }
    .content3.swiper-slide-active .right_img img{
        transform: none;
        transition: all 1s ease 2.6s;
    }
    .content2 .quantity{
        /* width: 40%; */
        /* right: 16px; */
        position: relative;
        text-align: left;
        /* margin-top: -50px; */
        margin-top: calc(-50 * 100vh / 844);
        display: flex;
        flex-direction: column;
        /* padding-right: 16px; */
    } 
    .content2 .quantity p {
        display: flex;
        align-items: center;
        margin-left: auto;
        width: 50%;
        opacity: 0;
        transform: translateY(100px);
        transition: all .3s ease;
    }
    .content2.swiper-slide-active .quantity .qu_title {
        opacity: 1;
        transform: none;
        transition: all 1s ease 1.8s;
    }
    .content2.swiper-slide-active .quantity .p1 {
        opacity: 1;
        transform: none;
        transition: all 1s ease 2.1s;
    }
    .content2.swiper-slide-active .quantity .p2 {
        opacity: 1;
        transform: none;
        transition: all 1s ease 2.4s;
    }
    .content2 .quantity p span {
        display: block;
        margin-left: auto;
        /* font-size: 28px; */
        font-size: calc(28 * 100vh / 844);
        font-weight: bold;
        /* margin-right: 5px; */
        margin-right: calc(5 * 100vh / 844);
    }
    .content2 .quantity .p1 {
        color: #5470c6;
    }
    .content2 .quantity .p2 {
        color: #92cc76;
    }
    .content2 .quantity p img {
        /* width: 30px;
        height: 30px;    */
        width: calc(30 * 100vh / 844);
        height: calc(30 * 100vh / 844);
        /* margin-right: 9px;
        margin-left: -3px; */
        margin-right: calc(9 * 100vh / 844);
        margin-left: calc(-3 * 100vh / 844);
    }
    /* .content3 .read-div {
        margin: 0 16px;
        background-color: #d3ecdc;
        border-radius: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
    } */
    .content3 .vice-title {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        /* margin: 15px 0; */
        margin: calc(15 * 100vh / 844) 0;
        transform: translateY(100px) scale(0);
        transition: all .3s;
    }
    .content3.swiper-slide-active .vice-title {
        transform: none;
        transition: all 1s ease 2.2s;
    }
    .content3 ul {
        list-style: none;
        background-color: #d3ecdc;
        /* margin: 0 16px; */
        margin: 0 calc(16 * 100vh / 844);
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
        /* padding: 20px 16px; */
        padding: calc(20 * 100vh / 844) calc(16 * 100vh / 844);
        /* border-radius: 15px; */
        border-radius: calc(15 * 100vh / 844);
        transform: translateY(50px) scale(0);
        transition: all .3s;
        /* padding-bottom: 0; */
    }
    .content3.swiper-slide-active .ul1 {
        transform: none;
        transition: all 1s ease 2.4s;
    }
    .content3.swiper-slide-active .ul2 {
        transform: none;
        transition: all 1s ease 2.6s;
    }
    .content3.swiper-slide-active .ul3 {
        transform: none;
        transition: all 1s ease 2.8s;
    }
    .content3 ul:last-child {
        margin-bottom: 0;
    }
    .content3 ul li {
        /* font-size: 20px; */
        font-size: calc(20 * 100vh / 844);
        /* margin-bottom: 15px; */
        margin-bottom: calc(15 * 100vh / 844);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content3 ul li:last-child {
        margin-bottom: 0;
    }
    .content3 ul li span {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        line-height: 1;
    }
    .describe img {
        /* width: 20px;
        height: 20px; */
        width: calc(20 * 100vh / 844);
        height: calc(20 * 100vh / 844);
        /* margin-right: 10px; */
        margin-right: calc(10 * 100vh / 844);
    }
    .describe {
        display: flex;
        align-items: center;
        line-height: 1;
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        /* padding-top: 10px; */
        padding-top: calc(10 * 100vh / 844);
    }
    .content3 .describe {
        opacity: 0;
        transform: translateY(50px);
        transition: all .3s;
    }
    .content3.swiper-slide-active .describe {
        transform: none;
        transition: all 1s ease 3s;
        opacity: 1;
    }
    .content4 .title {
        /* margin-top: 50px; */
        margin-top: calc(50 * 100vh / 844);
        position: relative;
    }
    .content4 .section {
        position: relative;
    }
    .content4 .section p {
        width: 80%;
        margin: 0 auto;
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        transform: translateY(100px);
        opacity: 0;
        transition: all .3s ease;
    }
    .content4.swiper-slide-active .section p {
        transform: none;
        opacity: 1;
    }
    .content4.swiper-slide-active .section .p1 {
        transition: all 1s ease .3s;
    }
    .content4.swiper-slide-active .section .p2 {
        transition: all 1s ease .9s;
    }
    .content4.swiper-slide-active .section .p3 {
        transition: all 1s ease 1.5s;
    }
    .content4.swiper-slide-active .section .p4 {
        transition: all 1s ease 2.1s;
    }
    .content4.swiper-slide-active .section .p5 {
        transition: all 1s ease 2.7s;
    }
    .content4.swiper-slide-active .section .p6 {
        transition: all 1s ease 3.3s;
    }
    .content4 .section p span {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
        /* margin: 0 5px; */
        margin: 0 calc(5 * 100vh / 844);
    }
    .content4 .section .line {
        display: block;
        width: 0;
        height: 2px;
        background-color: #6db2a2;
        /* margin: 10px auto; */
        margin: calc(10 * 100vh / 844) auto;
        /* margin-bottom: 40px; */
        margin-bottom: calc(40 * 100vh / 844);
        transition: all .3s;
        transform: translateY(100px);
    }
    .content4.swiper-slide-active .section .line {
        width: 80%;
        transform: none;
    }
    .content4.swiper-slide-active .section .line1 {
        transition: all 1.2s ease .6s;
    }
    .content4.swiper-slide-active .section .line2 {
        transition: all 1.2s ease 1.2s;
    }
    .content4.swiper-slide-active .section .line3 {
        transition: all 1.2s ease 1.8s;
    }
    .content4.swiper-slide-active .section .line4 {
        transition: all 1.2s ease 2.4s;
    }
    .content4.swiper-slide-active .section .line5 {
        transition: all 1.2s ease 3s;
    }
    .content4.swiper-slide-active .section .line6 {
        transition: all 1.2s ease 3.6s;
    }
    .content4 .left_img {
        /* bottom: 20px; */
        bottom: calc(20 * 100vh / 844);
    }
    .content4 .left_img img {
        width: 50%;
        transform: scale(0);
        transition: all .3s;
    }
    .content4.swiper-slide-active .left_img img {
        transform: none;
        transition: all 1s ease 3.3s;
    }
    .content4 .describe {
        justify-content: flex-end;
        position: absolute;
        /* bottom: 30px; */
        bottom: calc(30 * 100vh / 844);
        right: 0;
        opacity: 0;
        transform: translateY(60px);
        transition: all .3s;
    }
    .content4.swiper-slide-active .describe {
        transform: none;
        opacity: 1;
        transition: all 1s ease 3.5s;
    }
    .content4 .describe img{
        order: 1;
        /* margin-left: 10px; */
        margin-left: calc(10 * 100vh / 844);
        margin-right: 0;
    }
    .content5 {
        height: auto;
    }
    .content5 .section {
        /* margin: 0 16px; */
        margin: 0 calc(16 * 100vh / 844);
        /* border-radius: 20px; */
        border-radius: calc(20 * 100vh / 844);
        background-color: #d3ecdc;
        /* padding: 20px 0; */
        padding: calc(20 * 100vh / 844) 0;
        position: relative;
        transform: scale(0);
        opacity: 0;
        transition: all .3s;
        /* margin-top: 30px; */
        margin-top: calc(30 * 100vh / 844);
    }
    .content5.swiper-slide-active .section {
        opacity: 1;
        transform: none;
        transition: all 1s ease;
    }
    .content5 .title {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        background-color: #6db2a2;
        color: #ffffff;
        font-weight: bold;
        width: 60%;
        /* border-radius: 25px; */
        border-radius: calc(25 * 100vh / 844);
        margin: 0 auto;
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
        transform: scale(0);
        opacity: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .title {
        opacity: 1;
        transform: none;
        transition: all 1s ease 1s;
    }
    .content5 .vice-title {
        width: 0;
        /* padding: 5px 0; */
        padding: calc(5 * 100vh / 844) 0;
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        color: #ffffff;
        background-color: #6db2a2;
        /* border-top-right-radius: 25px; */
        border-top-right-radius: calc(25 * 100vh / 844);
        /* border-bottom-right-radius: 25px; */
        border-bottom-right-radius: calc(25 * 100vh / 844);
        transition: all .3s;
        transform-origin: left;
        overflow: hidden;
        white-space: nowrap;
        /* margin-bottom: 15px; */
        margin-bottom: calc(15 * 100vh / 844);

    }
    .content5.swiper-slide-active .vice-title {
        /* width: 220px; */
        width: calc(220 * 100vh / 844);
        transition: all 1s ease-in 1.2s;
    }
    .content5 .vice-title .text {
        opacity: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .vice-title .text {
        opacity: 1;
        transition: all .5s 2.2s;
    }

    .content5 .vice-title .num {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
        /* margin: 0 5px; */
        margin: 0 calc(5 * 100vh / 844);
        opacity: 0;
        transform: scale(0);
        transition: all .3s;
        
    }
    .content5.swiper-slide-active .vice-title .num {
        opacity: 1;
        transform: none;
        transition: all 1s ease 2.3s;
    }
    .content5 .unit-rank,.content5 .act-distribute,.content5 .act-rank {
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
    }
    .content5 .unit-rank p,.content5 .act-distribute p,.content5 .act-rank p{
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.3;
        transform: translateY(80px);
        opacity: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .unit-rank p,.content5.swiper-slide-active .act-distribute p,.content5.swiper-slide-active .act-rank p {
        opacity: 1;
        transform: none;
    }
    .content5.swiper-slide-active .unit-rank .p1 {
        transition: all 1s ease 1.7s;
    }
    .content5.swiper-slide-active .unit-rank .line1 {
        transition: all 1s ease 2.0s;
    }
    .content5.swiper-slide-active .unit-rank .p2 {
        transition: all 1s ease 2.3s;
    }
    .content5.swiper-slide-active .unit-rank .line2 {
        transition: all 1s ease 2.6s;
    }
    .content5.swiper-slide-active .unit-rank .p3 {
        transition: all 1s ease 2.9s;
    }
    .content5.swiper-slide-active .act-distribute .p1 {
        transition: all 1s ease 3.5s;
    }
    .content5.swiper-slide-active .act-distribute .line1 {
        transition: all 1s ease 3.8s;
    }
    .content5.swiper-slide-active .act-distribute .p2 {
        transition: all 1s ease 4.1s;
    }
    .content5.swiper-slide-active .act-distribute .line2 {
        transition: all 1s ease 4.4s;
    }
    .content5.swiper-slide-active .act-distribute .p3 {
        transition: all 1s ease 4.7s;
    }
    .content5.swiper-slide-active .act-distribute .line3 {
        transition: all 1s ease 5s;
    }
    .content5.swiper-slide-active .act-distribute .p4 {
        transition: all 1s ease 5.3s;
    }
    .content5.swiper-slide-active .act-rank .p1 {
        transition: all 1s ease 5.8s;
    }
    .content5.swiper-slide-active .act-rank .line1 {
        transition: all 1s ease 6.1s;
    }
    .content5.swiper-slide-active .act-rank .p2 {
        transition: all 1s ease 6.4s;
    }
    .content5.swiper-slide-active .act-rank .line2 {
        transition: all 1s ease 6.7s;
    }
    .content5.swiper-slide-active .act-rank .p3 {
        transition: all 1s ease 7s;
    }
    .content5 .unit-rank p span,.content5 .act-distribute p span,.content5 .act-rank p span {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
    }
    .content5 .unit-rank .line,.content5 .act-distribute .line,.content5 .act-rank .line {
        display: block;
        width: 100%;
        background-color: #fff;
        height: 2px;
        /* margin: 8px 0; */
        margin: calc(8 * 100vh / 844) 0;
        width: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .unit-rank .line,.content5.swiper-slide-active .act-distribute .line,.content5.swiper-slide-active .act-rank .line {
        width: 100%;
    }
    .content5 .vice-title2 {
        position: relative;
        margin-left: auto;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        /* border-top-left-radius: 25px; */
        border-top-left-radius: calc(25 * 100vh / 844);
        /* border-bottom-left-radius: 25px; */
        border-bottom-left-radius: calc(25 * 100vh / 844);
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        width: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .vice-title2 {
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        transition: all 1s ease 3.2s;
    }
    .content5 .vice-title3 {
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        width: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .vice-title3 {
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        transition: all 1s ease 5.5s;
    }
    .content5 .act-rank {
        margin-bottom: 0;
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
    }
    .content5 .act-rank p .span1 {
        margin-bottom: 0;
        font-size: calc(18 * 100vh / 844);
        font-weight: normal;
    }
    .content5 .describe {
        /* margin-top: 10px; */
        margin-top: calc(10 * 100vh / 844);
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
        transform: translateY(80px);
        opacity: 0;
        transition: all .3s;
    }
    .content5.swiper-slide-active .describe {
        opacity: 1;
        transform: none;
        transition: all 1s ease 7.2s;
    }

    .content6 .title {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        font-weight: bold;
        /* margin: 20px 0; */
        margin: calc(20 * 100vh / 844) 0;
        position: relative;
        opacity: 0;
        transform: scale(0);
        transition: all .3s;
    }
    .content6.swiper-slide-active .title {
        opacity: 1;
        transform: none;
        transition: all 1s ease;
    }
    .content6 ul i {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #6db2a2;
        /* margin: 15px auto; */
        margin: calc(15 * 100vh / 844) auto;
        width: 0;
        transition: all .3s;
    }
    .content6 ul p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* opacity: 0;
        transform: translateY(80px);
        transition: all .3s; */
    }
    .content6 .list1 p {
        opacity: 0;
        transform: translateY(80px);
        transition: all .3s;
    }
    .content6.swiper-slide-active ul .li1 p {
        opacity: 1;
        transform: none;
        transition: all 1s ease .5s;
    }
    .content6 .list1 p span{
        opacity: 0;
        transform: scale(0);
        transition: all .3s;
    }
    .content6.swiper-slide-active ul .li1 p span{
        opacity: 1;
        transform: none;
        transition: all 1s ease .8s;
    }
    .content6.swiper-slide-active ul .li1 i{
        width: 100%;
        transition: all 1s ease 1.2s;
    }
    .content6.swiper-slide-active ul .li2 p {
        opacity: 1;
        transform: none;
        transition: all 1s ease 1.4s;
    }
    .content6.swiper-slide-active ul .li2 p span{
        opacity: 1;
        transform: none;
        transition: all 1s ease 1.7s;
    }
    .content6.swiper-slide-active ul .li2 i{
        width: 100%;
        transition: all 1s ease 2s;
    }
    .content6.swiper-slide-active ul .li3 p {
        opacity: 1;
        transform: none;
        transition: all 1s ease 2s;
    }
    .content6.swiper-slide-active ul .li3 p span{
        opacity: 1;
        transform: none;
        transition: all 1s ease 2.3s;
    }
    /* .content6.swiper-slide-active ul .li3 i{
        width: 100%;
        transition: all 1s ease 2.6s;
    } */
    .content6 ul p span {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
    }
    .content6 .section {
        height: 90%;
        position: relative;
    }
    .content6 .vice-title {
        /* width: 160px; */
        width: calc(160 * 100vh / 844);
        background-color: #6db2a3;
        color: #ffffff;
        /* margin: 30px 0 15px 0; */
        margin: calc(30 * 100vh / 844) 0 calc(15 * 100vh / 844) 0;
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        line-height: 1;
        /* padding: 8px 0; */
        padding: calc(8 * 100vh / 844) 0;
        border-top-right-radius: calc(20 * 100vh / 844);
        /* border-bottom-right-radius: 20px; */
        border-bottom-right-radius: calc(20 * 100vh / 844);
        width: 0;
        transition: all .3s;
        white-space: nowrap;
    }
    .content6.swiper-slide-active .vice-title {
        /* width: 160px; */
        width: calc(160 * 100vh / 844);
        transition: all 1s ease 2.6s;
    }
    .content6 .vice-title span {
        opacity: 0;
        transition: all .3s;
    }
    .content6.swiper-slide-active .vice-title span {
        opacity: 1;
        transition: all 1s ease 3s;
    }
    .content6 .list2 li{
        text-align: left;
        line-height: 1;
        /* margin-bottom: 10px; */
        margin-bottom: calc(10 * 100vh / 844);
        opacity: 0;
        transform: translateY(50px) scale(0);
        transition: all .3s;
    }
    .content6.swiper-slide-active .list2 li {
        opacity: 1;
        transform: none;
    }
    .content6.swiper-slide-active .list2 .item1 {
        transition: all 1s ease 3s;
    }
    .content6.swiper-slide-active .list2 .item2 {
        transition: all 1s ease 3.2s;
    }
    .content6.swiper-slide-active .list2 .item3 {
        transition: all 1s ease 3.4s;
    }
    .content6.swiper-slide-active .list2 .item4 {
        transition: all 1s ease 3.6s;
    }
    .content6.swiper-slide-active .list2 .item5 {
        transition: all 1s ease 3.8s;
    }
    .content6.swiper-slide-active .list2 .item6 {
        transition: all 1s ease 4s;
    }
    .content6 .block {
        display: inline-block;
        /* width: 25px; */
        width: calc(25 * 100vh / 844);
        /* height: 14px; */
        height: calc(14 * 100vh / 844);
        /* border-radius: 3px; */
        border-radius: calc(3 * 100vh / 844);
        /* margin-right: 5px; */
        margin-right: calc(5 * 100vh / 844);
    }
    .content6 .describe {
        padding-top: 0;
        /* margin-top: -10px; */
        margin-top: calc(-10 * 100vh / 844);
        opacity: 0;
        transform: translateY(80px);
        transition: all .3s;
    }
    .content6.swiper-slide-active .describe {
        opacity: 1;
        transform: none;
        transition: all 1s ease 4s;
    }
    @keyframes swingC6 { /*   animation: 5s ease 0s infinite normal both running swing; */
        20% {
            transform: rotate(15deg) translate(25%, -50%);
        }
        40% {
            transform: rotate(-10deg) translate(25%, -50%);
        }
        60% {
            transform: rotate(5deg) translate(25%, -50%);
        }
        80% {
            transform: rotate(-5deg) translate(25%, -50%);
        }
        100% {
            transform: rotate(0deg) translate(25%, -50%);
        }
    }
    .content6 #cont6-echart {
        transform: scale(0);
        transition: all .3s;
    }
    .content6.swiper-slide-active #cont6-echart {
        transform: none;
        transition: all 1s ease 3.5s;
    }
    .content6 .right_img {
        width: 55%;
        top: 50%;
        right: 0;
        transform: translate(25%, -50%);
        animation: 5s ease 0s infinite normal both running swingC6;
    }
    .content6 .right_img img {
        width: 100%;
        transform: scale(0);
        transition: all .3s;
    }
    .content6.swiper-slide-active .right_img img {
        transform: none;
        transition: all 1s ease 3.8s;
    }
    .content7 {
        z-index: 1;
    }
    .content7 .left_img {
        transform: scale(0);
        transition: all .3s;
    }
    .content7.swiper-slide-active .left_img {
        transform: none;
        transition: all 1s ease;
    }
    .content7 .section {
        /* margin: 0 16px; */
        margin: 0 calc(16 * 100vh / 844);
        height: 90%;
        background-color: #d3ecdc;
        /* border-radius: 20px; */
        border-radius: calc(20 * 100vh / 844);
        position: relative;
        overflow: hidden;
        top: 50%;
        /* left: 50%; */
        transform: translateY(-50%) scale(0);
        opacity: 0;
        transition: all .3s;
    }
    .content7.swiper-slide-active .section {
        opacity: 1;
        transform: translateY(-50%);
        transition: all 1s ease .2s;
    }
    .content7 .title {
        width: 60%;
        /* padding: 10px 0; */
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        color: #ffffff;
        background-color: #6db2a2;
        /* border-radius: 30px; */
        border-radius: calc(30 * 100vh / 844);
        /* margin: 25px auto; */
        margin: calc(25 * 100vh / 844) auto;
        /* margin-bottom: 35px; */
        margin-bottom: calc(35 * 100vh / 844);
        font-weight: bold;
        transform: scale(0);
        transition: all .3s;
    }
    .content7.swiper-slide-active .title {
        transform: none;
        transition: all 1s ease 1s;
    }

    .content7 .vice-title {
        /* width: 180px;     */
        width: calc(180 * 100vh / 844);    
        /* padding: 5px 0; */
        padding: calc(5 * 100vh / 844) 0;
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        color: #ffffff;
        background-color: #6db2a2;
        /* border-top-right-radius: 25px; */
        border-top-right-radius: calc(25 * 100vh / 844);
        /* border-bottom-right-radius: 25px; */
        border-bottom-right-radius: calc(25 * 100vh / 844);
        transition: all .3s;
        transform-origin: left;
        overflow: hidden;
        white-space: nowrap;
        /* margin-bottom: 30px; */
        margin-bottom: calc(30 * 100vh / 844);
        width: 0;
    }
    .content7.swiper-slide-active .vice-title1 {
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        transition: all 1s ease 1.3s;
    }
    .content7.swiper-slide-active .vice-title2 {
        /* width: 180px; */
        width: calc(180 * 100vh / 844);
        transition: all 1s ease 2.9s;
    }
    .content7 .vice-title2 {
        margin-left: auto;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        /* border-top-left-radius: 25px; */
        border-top-left-radius: calc(25 * 100vh / 844);
        /* border-bottom-left-radius: 25px; */
        border-bottom-left-radius: calc(25 * 100vh / 844);
    }
    .content7 .read-ov-rank {
        /* margin-bottom: 35px; */
        margin-bottom: calc(35 * 100vh / 844);
    }
    .content7 .platform {
        /* margin-bottom: 15px; */
        margin-bottom: calc(15 * 100vh / 844);
    }
    .content7 .read-ov-rank p,.content7 .platform p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.3;
        opacity: 0;
        transform: translateY(80px);
        transition: all .3s;
    }
    .content7.swiper-slide-active .read-ov-rank p,.content7.swiper-slide-active .platform p {
        opacity: 1;
        transform: none;
    }
    .content7.swiper-slide-active .read-ov-rank .p1 {
        transition: all 1s ease 1.5s;
    }
    .content7.swiper-slide-active .read-ov-rank .span1 {
        transition: all 1s ease 1.8s;
    }
    .content7.swiper-slide-active .read-ov-rank .line1 {
        transition: all 1s ease 1.8s;
    }
    .content7.swiper-slide-active .read-ov-rank .p2 {
        transition: all 1s ease 2.1s;
    }
    .content7.swiper-slide-active .read-ov-rank .span2 {
        transition: all 1s ease 2.4s;
    }
    .content7.swiper-slide-active .read-ov-rank .line2 {
        transition: all 1s ease 2.4s;
    }
    .content7.swiper-slide-active .read-ov-rank .p3 {
        transition: all 1s ease 2.7s;
    }
    .content7.swiper-slide-active .read-ov-rank .span3 {
        transition: all 1s ease 2.9s;
    }

    .content7.swiper-slide-active .platform .p1 {
        transition: all 1s ease 3.2s;
    }
    .content7.swiper-slide-active .platform .span1 {
        transition: all 1s ease 3.5s;
    }
    .content7.swiper-slide-active .platform .line1 {
        transition: all 1s ease 3.5s;
    }
    .content7.swiper-slide-active .platform .p2 {
        transition: all 1s ease 3.8s;
    }
    .content7.swiper-slide-active .platform .span2 {
        transition: all 1s ease 4.1s;
    }
    .content7.swiper-slide-active .platform .line2 {
        transition: all 1s ease 4.1s;
    }
    .content7.swiper-slide-active .platform .p3 {
        transition: all 1s ease 4.4s;
    }
    .content7.swiper-slide-active .platform .span3 {
        transition: all 1s ease 4.7s;
    }
    .content7 .read-ov-rank p span,.content7 .platform p span {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        font-weight: bold;
        opacity: 0;
        transform: scale(0);
        transition: all .3s;
    }
    .content7.swiper-slide-active .read-ov-rank p span,.content7.swiper-slide-active .platform p span {
        opacity: 1;
        transform: none;
    }
    .content7 .read-ov-rank .line,.content7 .platform .line {
        display: block;
        width: 100%;
        background-color: #fff;
        height: 2px;
        /* margin: 15px 0; */
        margin: calc(15 * 100vh / 844) 0;
        width: 0;
        transition: all .3s;
    }
    .content7.swiper-slide-active .read-ov-rank .line,.content7.swiper-slide-active .platform .line {
        width: 100%;
    }
    .content7 .read-img {
        /* width: 100px; */
        width: calc(100 * 100vh / 844);
        /* height: 100px; */
        height: calc(100 * 100vh / 844);
        transform: scale(0);
        transition: all .3s;
    }
    .content7.swiper-slide-active .read-img {
        transform: none;
        transition: all 1s ease 4.6s;
    }
    .content7 .describe {
        text-align: left;
        transform: translateY(80px);
        opacity: 0;
        transition: all .3s;
    }
    .content7.swiper-slide-active .describe {
        opacity: 1;
        transform: none;
        transition: all 1s ease 4.9s;
    }
    .footer {
        height: auto;
        position: relative;
        /* margin-bottom: 40px; */
        margin-bottom: calc(40 * 100vh / 844);
    }
    .footer .section {
        /* padding: 30px 0; */
        padding: calc(30 * 100vh / 844) 0;
        /* margin: 0 16px; */
        margin: 0 calc(16 * 100vh / 844);
        background-color: #d3ecdc;
        /* border-radius: 20px; */
        border-radius: calc(20 * 100vh / 844);
        position: relative;
        transform: translateY(-50%) scale(0);
        transition: all .3s;
        opacity: 0;
        top: 50%;
    }
    .footer.swiper-slide-active .section {
        opacity: 1;
        transform: translateY(-50%);
        transition: all 1s ease .5s;
    }
    .footer .section p {
        text-align: justify;
        text-indent: 2em;
        /* font-size: 20px; */
        font-size: calc(20 * 100vh / 844);
        transform: translateY(80px);
        opacity: 0;
        transition: all .3s;
    }
    .footer.swiper-slide-active .section p {
        transform: none;
        opacity: 1;
        transition: all 1s ease 1.2s;
    }
    .footer .section img {
        /* width: 150px; */
        width: calc(150 * 100vh / 844);
        /* margin-top: 15px; */
        margin-top: calc(15 * 100vh / 844);
        transform: scale(0);        
        transition: all .3s;
    }
    .footer.swiper-slide-active .section img {
        transform: none;        
        transition: all 1s ease 1.2s;
    }
    .footer .right_img {
        width: 55%;
        right: 0;
        top: auto;
        bottom: 0;
        transform: translateY(-25%) scale(0);
        transition: all .3s;
    }
    .footer .left_img {
        transform: scale(0);
        transition: all .3s;
    }
    .footer.swiper-slide-active .left_img {
        transform: none;
        transition: all 1s ease;
    }
    .footer.swiper-slide-active .right_img {
        transform: translateY(-25%);
        transition: all 1s ease 1.5s;
    }
    .footer .right_img img {
        width: 100%;
    }
</style>