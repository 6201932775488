<template>
    <div ref="loadmian" class="main-load">
        <ul>
            <li v-for="item in 12" :key="item"></li>
        </ul>
    </div>
</template>

<script>
import { find, findAll } from './utils';
    export default {
        mounted() {
            const $loadmian = this.$refs.loadmian
            const $ul = find($loadmian, 'ul')
            const $li = findAll($ul, 'li')
            let tl = this.gsap.timeline()
            let tl1 = this.gsap.timeline()
            tl
                .from($li[0], 1, { opacity: .9, rotateY: '80deg' })
                .from($li[1], 1.1, { opacity: .8, rotateY: '65deg' }, '-=.7')
                .from($li[2], 1.2, { opacity: .7, rotateY: '50deg' }, '-=.8')
                .from($li[3], 1.3, { opacity: .6, rotateY: '35deg' }, '-=.9')
                .from($li[4], 1.4, { opacity: .5, rotateY: '30deg' }, '-=1')
                .from($li[5], 1.4, { opacity: .4, rotateY: '15deg' }, '-=1')

            tl1    
                .from($li[11], 1, { opacity: .9, rotateY: '-80deg' })
                .from($li[10], 1.1, { opacity: .8, rotateY: '-65deg' }, '-=.7')
                .from($li[9], 1.2, { opacity: .7, rotateY: '-50deg' }, '-=.8')
                .from($li[8], 1.3, { opacity: .6, rotateY: '-35deg' }, '-=.9')
                .from($li[7], 1.4, { opacity: .5, rotateY: '-30deg' }, '-=1')
                .from($li[6], 1.4, { opacity: .4, rotateY: '-15deg' }, '-=1')
                .to($li, 1, { opacity: 0 }, '-=1')
                .to($ul, .5, { background: '#d3ecdc' }, '-=.9')
                .to($ul, 1, { autoAlpha: 0 }, '-=.5')

        },
        methods: {

        }

    }
</script>

<style scoped>
    .main-load {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: initial;
    }
    .main-load.active {
        z-index: 2;
    }
    div {
        width: 100%;
        position: relative;
    }
    ul,li{
        list-style: none;
    }
    ul {
        width: 100%;
        height: 100vh;
        display: flex;
    }
    li {
        flex: 1;
        background-color: #6db2a3;
    }
</style>